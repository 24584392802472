import React from 'react';

import Layout from '../components/layout';
import Suits from '../components/suits';

const Offerings = () => (
	<Layout>
		<h2>Intro to Poker</h2>
		<p>
			Learn to play poker in a comfortable and relaxed small-group setting with
			up to 5 other students. This class is geared toward beginners and players
			new to the game.
		</p>
		<br />
		<p>Topics covered include:</p>
		<ul>
			<li>No Limit Hold’em: the rules and the objectives of the game</li>
			<li>
				Playing live poker: game flow, what to expect in a casino or a cardroom,
				and table etiquette
			</li>
		</ul>
		<br />
		<p>
			The aim of this class is for students to be comfortable playing no limit
			hold'em; there will not be an emphasis on strategy.
		</p>
		<br />
		<p>
			In-person classes happen monthly in Brooklyn, NY. Zoom classes also
			available.
		</p>
		<br />
		<p>Cost: $50 for a 2-hour session</p>
		<br />
		<div className='suits'>
			<Suits />
		</div>
		<br />
		<h2>No Limit Hold'em Strategy</h2>
		<p>
			Learn no limit hold'em strategy in a focused small-group setting with up
			to 5 other students. This class happens twice a month and is geared toward
			students with a familiarity of NLHE game play and mechanics who are
			looking to beat their regular live low-stakes game (1/2NL, 1/3NL, 2/5NL).
		</p>
		<br />
		<p>Topics covered include:</p>
		<ul>
			<li>
				General poker principles: table position, aggression, how much to bet,
				when to bet, and more
			</li>
			<li>Hand ranges: which types of hands to play in which situations</li>
			<li>
				Live poker concepts: what to expect in a casino or a cardroom, player
				types and tells, rake considerations, and more
			</li>
			<li>
				Hand breakdowns: get feedback on your play and learn what factors should
				impact your decision-making
			</li>
		</ul>
		<br />
		<p>
			Concepts covered cater to student experience, and students receive
			feedback and assignments to improve their play.
		</p>
		<br />
		<p>
			In-person classes happen twice a month in Brooklyn, NY, with periodic
			field trips to cardrooms or casinos to put learned principles into action.
			Zoom classes also available.
		</p>
		<br />
		<p>Cost: $90 for a 3-hour session</p>
		<br />
		<div className='suits'>
			<Suits />
		</div>
		<br />
		{/* <h2>Poker Field Trip</h2>
		<p>
			Students play a live session at a cardroom or a casino and practice
			profiling players, taking notes on hands, and applying learned strategy.
			Following a pre-session warm up, students receive feedback from 1 hour of
			observed play at their preferred stakes. The trip culminates with a
			session debrief to review the session and notable hands.
		</p>
		<br />
		<p>
			Cost: $60 for the pre-session warmup, hour of observed play, and
			post-session debrief. Transportation to and from the venue not included.
		</p>
		<br />
		<div className='suits'>
			<Suits />
		</div>
		<br /> */}
		{/* <h2>Focused Live Session</h2>
		<p>
			For intermediate to advanced students who want an in-depth review of their
			play beyond a 1-hour observation during a field trip. Live sessions
			involve deep dive analysis on hands played as well as table dynamics and
			adjustments.
		</p>
		<br />
		<p>
			Live sessions take place at a cardroom or casino and include the
			following:
		</p>
		<ul>
			<li>Pre-session warmup (30min)</li>
			<li>3 hours of play at customary game/stake</li>
			<li>Post-session discussion (30min)</li>
			<li>Custom session review document with actionable plan</li>
			<li>Document review session (1hr)</li>
		</ul>
		<br />
		<p>Cost: $500</p>
		<br />
		<div className='suits'>
			<Suits />
		</div>
	<br /> */}
		<h2>Poker Night</h2>
		<p>
			Want to host a poker night for a group of friends? Poker Night is geared
			toward beginners who want to learn the game in a fun, casual setting with
			friends and claim some bragging rights in the process. An instructor comes
			to your home (or other setting) and teaches the basics of the game before
			dealing either a low buy-in cash game or tournament for up to 10
			participants.
		</p>
		<br />
		<p>
			Cards and poker chips are provided as part of the cost; poker table rental
			available for an additional fee. Drinks and snacks not provided.
		</p>
		<br />
		<p>Cost: $80 per participant</p>
		<br />
		<div className='suits'>
			<Suits />
		</div>
		<br />
		<h2>Personalized Coaching</h2>
		<p>
			Private lessons are available and will be tailored to meet student need.
			Lessons are available in person or via Zoom.
		</p>
		<br />
		<p>Cost: $90 per hour</p>
		<br />
		<div className='suits'>
			<Suits />
		</div>
		<br />
		<p>
			Text Johnnie at <a href='sms:+16469024908'>646 902 4908</a> for more
			information or to schedule a free 15-minute consultation.
		</p>
	</Layout>
);

export default Offerings;
